import EntityForm from "@/mixins/EntityForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    infoBoxService: "infoBoxService",
    languageService: "languageService",
    eventService:"eventService",
    categoryService: "categoryService"
  },
  watch: {

  },
})
export default class InfoBoxForm extends mixins(EntityForm) {
  model = {};
  platformLanguages = [];

  get translations() {
    return this.$store.state.translationsStore.currentLang;
  }

  get selectedLanguages() {
    const modelLanguages = this.platformLanguages;
    return this.platformLanguages.filter((l) => {
      return modelLanguages.includes(l.id);
    });
  }

  infoBoxRequiredFields() {
    return true;
  }

  get infoBoxElements() {
    return [
      {
        id: "code",
        label: this.translations.labels.infoBox_form_code,
        type: "text",
        rules: "max:15",
        required: this.infoBoxRequiredFields
      },
      {
        id: "width",
        label: this.translations?.labels?.infoBox_form_width,
        type: "number",
        step: 0.25,
        rules: "min:0|max:100",
        hint: this.translations?.labels?.infoBox_form_perc_hint,
        hide: () => {
          return !this.withInfoBoxIcon();
        }
      },
      {
        id: "height",
        label: this.translations?.labels?.infoBox_form_height,
        type: "number",
        step: 0.25,
        rules: "min:0|max:100",
        hint: this.translations?.labels?.infoBox_form_perc_hint,
        hide: () => {
          return !this.withInfoBoxIcon();
        }
      },
      {
        id: "x",
        label: this.translations?.labels?.infoBox_form_x,
        type: "number",
        rules: "min:0|max:100",
        hint: this.translations?.labels?.infoBox_form_perc_hint,
        step: 0.25,
        hide: () => {
          return !this.withInfoBoxIcon();
        }
      },
      {
        id: "y",
        label: this.translations?.labels?.infoBox_form_y,
        type: "number",
        rules: "min:0|max:100",
        hint: this.translations?.labels?.infoBox_form_perc_hint,
        step: 0.25,
        hide: () => {
          return !this.withInfoBoxIcon();
        }
      },
      {
        id: "localizations",
        type: "localizations",
        header: this.translations.labels.infoBox_form_localizations,
        languages: this.languages,
        resetColumns: true,
        elements: [
          {
            id: "title",
            label: this.translations.labels.infoBox_form_title,
            type: "text",
            rules: "max:200",
            required: this.infoBoxRequiredFields

          },
          {
            id: "content",
            label: this.translations.labels.infoBox_form_content,
            type: "wysiwyg",
            required: this.infoBoxRequiredFields,
            resetColumns: 1,
          }
        ],
      },
    ];
  }

  get elements() {
    return [
      {
        legend: this.translations.labels.commonFieldset_generalData,
        type: "fieldset",
        group: "default",
        collapsible: true,
        open: true,
        resetColumns: true,
        elements: this.infoBoxElements
      }
    ];
  }

  get languages() {
    return this.platformLanguages;
  }

  get helpLink() {
    return this.translations.pageHelp.infoBox;
  }

  withInfoBoxIcon() {
    return true;
  }

  // eslint-disable-next-line no-empty-function
  afterLoad() { }

  async created() {
    this.platformLanguages = await this.eventService.relatedLanguages(
      this.$route.params.idParent
    );

    this.afterLoad();
  }
}
